<template>
    <Table :typeface="manuka" routerlink="manuka" v-if="manuka"/>
</template>

<script>
import Table from '../components/Table.vue'

export default {
    components: {
        Table
    },
    props: {
        manuka: Array
    }
}
</script>

<style>

</style>