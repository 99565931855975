<template>
    <div
        id="plate-container"
        :class="{ focus: $store.state.focus != null, clocks: $store.state.clocks == true, darkmode : $store.state.darkMode == true, plate : $store.state.plate == true }"
    >
        <section v-for="object, i in typeface" :key="i"
                 :class="[object.css_animation, object.slug]"
                 :id="'plate-' + i"
                 @click="enterPlate(i)"
                 v-show="($store.state.focus == null || $store.state.focus == i) && object.fieldset == 'font-style'">
            <div class="center-content">
                <p
                    :class="object.style"
                    contenteditable
                    autocomplete="off"
                    autocorrect="off"
                    autocapitalize="off"
                    spellcheck="false">
                    <!-- {{ object.name }} -->
                    {{ randomChar }}
                </p>
            </div>
            <div class="center-content">
                <img :src="$store.state.fileBaseURL + object.id + '/' + object.backdrop_file"/>
            </div>
        </section>
    </div>
</template>

<script>

export default {
    name: 'Table',
    props: {
        typeface: Array,
        routerlink: String
    },
    mounted() {
        this.$store.commit("setFocus", null);
        setTimeout(() => {
            this.$store.state.clocks = false;
        }, 2000);

        setTimeout(() => {
            this.$props.typeface.forEach((style) => {
                var list = document.querySelectorAll(`.${style['slug']} p`);
                for (let i = 0; i < list.length; i++) {
                    list[i].style.fontFamily = `${style['title']}, sans-serif`;
                }
            });
        }, 10);

        if (document.body.classList.contains("darkmode")) {
            document.body.classList.remove("darkmode");
            this.$store.commit('toggleLightDark');
        }

        if (this.$store.state.plate === false) {
            this.$store.commit('togglePlate');
        }

    },
    computed: {
        randomChar() {
            let list = ['A', 'G', 'X'];
            return list[Math.floor(Math.random() * list.length)];
        }
    },
    methods: {
        enterPlate(key) {
            this.$store.commit("setFocus", key);
            this.$router.push(`/${ this.$props.routerlink }`);
        },
    },
};
</script>
<style scoped>

#plate-container {
    top: 0;
    padding: 6rem 2rem 6rem 2rem;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    transition: padding-top 1s ease-out, opacity 1s ease-out 1s;
    opacity: 1;
}

#plate-container.clocks img {
    filter: contrast(0%) brightness(125%);
}

#plate-container.clocks section p {
    opacity: 0;
}

/* #plate-container.focus {
  pointer-events: none;
} */

#plate-container.plate img {
    opacity: 1;
}

#plate-container section {
    height: min(25vw, 20vh);
    width: 25%;
    flex-grow: 0;
    transition: height 1s;
}

/* objects within section */
#plate-container .center-content {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: calc(50% - 6rem);
    /* height: min(25vw, 20vh); */
    width: 25%;
    padding: 24px 24px 10px 24px;
}

@supports (-moz-appearance:none) {
    #plate-container section {
        padding: 0px;
        max-height: calc(50%);
    }
}

#plate-container img {
    position: relative;
    object-fit: contain;
    height: auto;
    width: 100%;
    /* mix-blend-mode: lighten; */
    pointer-events: none;
    transition: all 1s;
    opacity: 0;
}

.center-content p {
    position: relative;
    z-index: 2;
    /* margin-top: -0.05em; */
    font-size: max(15vw, 240px);
    color: rgb(0, 0, 0);
    line-height: 1;
    pointer-events: none;
    max-width: 100%;
    /* padding: 40px; */
    white-space: pre;
    /* max-height: 80%; */
    overflow: hidden;
    opacity: 1;
    margin-top: -1rem;
    transition: opacity 1s 0s;
}

/* section.focus p {
  pointer-events: auto;
} */

section:hover {
    cursor: pointer;
}

/* section.focus:hover {
  cursor: auto;
} */

/* section:hover p {
  font-style: italic;
} */

/* section.focus:hover p {
  font-style: normal;
} */

section img {
    will-change: transform;
}

.flipY img {
    transform: rotateX(0deg);
    transition: transform 0.5s 0s;
    /* transform-style: preserve-3d; */
}

.flipY:hover img {
    transform: rotateX(45deg);
}

.rotate img {
    transform: rotateZ(0deg);
    transition: transform 0.5s;
}

.rotate:hover img {
    transform: rotateZ(45deg);
}

.perspective-rotate img {
    transition: transform 0.5s;
    transform: rotateX(45deg) rotateZ(45deg);
}

.perspective-rotate:hover img {
    transform: rotateX(45deg) rotateZ(0deg);
}

@media only screen and (max-width: 768px) {
    #plate-container {
        overflow: auto;
        height: unset;
        padding: 6rem 1rem 8rem 1rem;
    }

    #plate-container section {
        position: relative;
        height: min(50vw, 50vh);
        width: 50%;
    }

    #plate-container .center-content {
        height: min(50vw, 50vh);
        /* height: min(50vw, 50vh); */
        width: 100%;
        padding: 12px 12px 0px 12px;
    }

    .center-content p {
        position: relative;
        margin-top: -0.05em;
        font-size: max(38vw, 145px);
    }
}

@media only screen and (max-height: 400px) {
    #plate-container {
        padding: 5rem 1rem 5rem 1rem;
    }

    #plate-container section {
        height: min(50vw, 70vh);
    }

    #plate-container .center-content {
        padding: 22px 22px 10px 22px;
    }

    .center-content p {
        font-size: max(21vw, 180px);
    }
}
</style>