<template>
    <RoundTable v-if="draft" :typeface="draft"/>
</template>

<script>
import RoundTable from '../components/RoundTable.vue'

export default {
    components: {
        RoundTable
    },
    props: {
        draft: Array
    }
}
</script>

<style>
</style>