<template>
    <div id="round-table-container" class="center-content" @click="rotateTable($event)">
        <div id="round-table"
             :class="{ focus: $store.state.focus != null, clocks: $store.state.clocks == true, darkmode : $store.state.darkMode == true, plate : $store.state.plate == true }">
            <div v-for="object, i in typeface" :key="i"
                 class="center-content round-content"
                 :id="'plate' + i"
            >
                <div v-if="object.hasOwnProperty('slug')"
                     class="text-area center-content"
                     :class="object.slug">
                    <p
                        class="fitty-text"
                        :class="[{'visible': $store.state.focus == i }, object.style]"
                        contenteditable="plaintext-only"
                        autocomplete="off"
                        autocorrect="off"
                        autocapitalize="off"
                        spellcheck="false"
                    >
                        {{ object.title }}
                    </p>
                </div>
                <img v-if="object.hasOwnProperty('slug')"
                     :src="$store.state.fileBaseURL + object.id + '/' + object.backdrop_file"/>
                <p v-if="object.hasOwnProperty('type')" class="trial" :id="'trial-' + this.$route.path">
                    <a href="mailto:info@sundaylunch.biz?subject=Request Trials">
                        <span>R</span>
                        <span>e</span>
                        <span>q</span>
                        <span>u</span>
                        <span>e</span>
                        <span>s</span>
                        <span>t</span>
                        <br>
                        <span>T</span>
                        <span>r</span>
                        <span>i</span>
                        <span>a</span>
                        <span>l</span>
                        <span>s</span>
                    </a>
                </p>
            </div>
        </div>

        <Meta :typeData="typeface" v-if="typeface"/>
        <div id="mobile-nav" v-show="$store.state.$mobile">
            <img src="/img/unfold-01.bd67cca7.svg" style="transform: rotate(-90deg)"
                 @click="rotateTableWithNav('left')">
            <img src="/img/unfold-01.bd67cca7.svg" style="transform: rotate(90deg)"
                 @click="rotateTableWithNav('right')">
        </div>
    </div>
</template>

<script>
import Meta from './Meta.vue';
import fitty from 'fitty';

export default {
    components: {
        Meta
    },
    data() {
        return {
            p: [],
            myPList: null,
            overflow: null
        }
    },
    props: {
        typeface: Array
    },
    created() {
        window.addEventListener("resize", this.resizeRoundTable);
    },
    unmounted() {
        window.removeEventListener("resize", this.resizeRoundTable);
    },
    computed: {
        focus() {
            return this.$store.state.focus;
        },
        metaSize() {
            return this.$store.state.metaSize;
        },
        spacing() {
            return this.$store.state.spacing;
        },
        leading() {
            return this.$store.state.leading;
        }
    },
    watch: {
        metaSize() {
            var textAreas = document.getElementById("round-table").getElementsByTagName("P");
            var roundContent = document.getElementsByClassName("text-area");


            if (this.$props.typeface[this.focus].title) {
                if (textAreas[this.$store.state.focus].clientHeight >= roundContent[this.$store.state.focus].clientHeight - 100) {
                    this.overflow = true;
                } else {
                    this.overflow = false;
                }
            }
        }
    },
    mounted() {
        if (this.$store.state.focus == null) {
            this.$store.commit('setFocus', 1);
        }

        let container = document.getElementById("round-table-container");
        let table = document.getElementById("round-table");
        let size;

        if (this.$store.state.$mobile) {
            size = window.innerWidth * 4;
        } else {
            size = window.innerWidth * 2;
        }

        table.style.height = size + 'px';
        table.style.width = size + 'px';

        this.distributeFields(size);
        this.initialRotation(table);
        this.fetchFonts();
        this.fetchTrialBannerFont();
        this.showArrows(container);

        // set P fields scalable
        this.myPList = document.querySelectorAll('.fitty-text');
        this.p = Array.from(this.myPList);

        this.p.forEach((element) => {
            fitty(element, {
                minSize: 12,
                maxSize: 200,
                multiLine: true
            });

            element.addEventListener('fit', (e) => {
                this.$store.commit('setMetaSize', Math.floor(e.detail.newValue))
            });
        });
    },
    methods: {
        initialRotation(container) {
            let steps = 360 / this.$props.typeface.length;
            let midStep = (this.$props.typeface.length / 2) * steps;

            container.style.transform = 'translate(0%, -41%) rotate(' + (midStep - ((360 / this.$props.typeface.length) * (this.focus + 3))) + 'deg)';

            var styleRoot = document.querySelector(':root');
            styleRoot.style.setProperty('--contrast-color', '#' + this.$props.typeface[this.focus].contrast_color);

            window.setTimeout(() => {
                container.style.transform = 'translate(0%, -41%) rotate(' + (midStep - ((360 / this.$props.typeface.length) * this.focus)) + 'deg)'
            }, 10);
        },
        showArrows(container) {
            container.addEventListener('mousemove', (event) => {
                if (event.clientX > window.innerWidth / 2) {
                    container.style.cursor = "url('right.png') 30 20, pointer";
                } else {
                    container.style.cursor = "url('left.png') 30 20, pointer";
                }
            });
        },
        distributeFields(size) {
            var myNodeList = document.querySelectorAll('.round-content');
            var fields = Array.from(myNodeList);
            var width = size / 2;
            var height = size / 2;
            var step = 2 * Math.PI / fields.length;
            var radius = 0.8 * width;

            var angle = -90 * Math.PI / 180;

            fields.forEach(function (element, index) {
                var x = Math.round(width + radius * Math.cos(angle));
                var y = Math.round(height + radius * Math.sin(angle));
                element.style.right = x + 'px';
                element.style.top = y + 'px';

                var rotationBase = -180;
                var rotation = rotationBase + 360 / fields.length * index;
                element.style.transform = 'translate(50%, -50%) rotate(' + rotation + 'deg)';


                angle = angle - step;
            });
        },
        rotateTable(event) {
            let meta = document.getElementById("meta-container");
            let mobileNav = document.getElementById("mobile-nav") ?? null;

            if (event.target.tagName !== "P" && event.target.tagName !== "A" && !meta.contains(event.target) && !mobileNav.contains(event.target)) {
                // next
                if (event.clientX > window.innerWidth / 2) {
                    if (this.focus === 0) {
                        this.$store.commit('setFocus', this.$props.typeface.length - 1);
                    } else {
                        this.$store.commit('setFocus', this.focus - 1);
                    }
                    let container = document.getElementById("round-table");
                    container.style.transform = 'translate(0%, -41%) rotate(' + (this.getCurrentRotation(container) + (360 / this.$props.typeface.length)) + 'deg)'
                } else {
                    // prev
                    if (this.focus === this.$props.typeface.length - 1) {
                        this.$store.commit('setFocus', 0);
                    } else {
                        this.$store.commit('setFocus', this.focus + 1);
                    }
                    let container = document.getElementById("round-table");
                    container.style.transform = 'translate(0%, -41%) rotate(' + (this.getCurrentRotation(container) - (360 / this.$props.typeface.length)) + 'deg)'
                }


                var styleRoot = document.querySelector(':root');
                if (this.$props.typeface[this.focus].contrast_color !== undefined) {
                    styleRoot.style.setProperty('--contrast-color', '#' + this.$props.typeface[this.focus].contrast_color);
                } else {
                    styleRoot.style.setProperty('--contrast-color', '#D9D9D9');
                }

                // this.$store.commit('setMetaSize', 100);
                this.overflow = false;
            }
        },
        rotateTableWithNav(direction) {
            if (direction === 'right') {
                if (this.focus === 0) {
                    this.$store.commit('setFocus', this.$props.typeface.length - 1);
                } else {
                    this.$store.commit('setFocus', this.focus - 1);
                }
                let container = document.getElementById("round-table");
                container.style.transform = 'translate(0%, -41%) rotate(' + (this.getCurrentRotation(container) + (360 / this.$props.typeface.length)) + 'deg)'
            } else {
                // prev
                if (this.focus === this.$props.typeface.length - 1) {
                    this.$store.commit('setFocus', 0);
                } else {
                    this.$store.commit('setFocus', this.focus + 1);
                }
                let container = document.getElementById("round-table");
                container.style.transform = 'translate(0%, -41%) rotate(' + (this.getCurrentRotation(container) - (360 / this.$props.typeface.length)) + 'deg)'
            }


            var styleRoot = document.querySelector(':root');
            if (this.$props.typeface[this.focus].contrast_color !== undefined) {
                styleRoot.style.setProperty('--contrast-color', '#' + this.$props.typeface[this.focus].contrast_color);
            } else {
                styleRoot.style.setProperty('--contrast-color', '#D9D9D9');
            }

            // this.$store.commit('setMetaSize', 100);
            this.overflow = false;

        },
        getCurrentRotation(el) {
            let rotate = el.style.transform.match(/rotate\((.+)\)/);

            return parseInt(rotate[1].slice(0, -3));
        },
        resizeRoundTable() {
            var container = document.getElementById("round-table");
            var size;

            if (this.$store.state.$mobile) {
                size = window.innerWidth * 4;
            } else {
                size = window.innerWidth * 2;
            }

            container.style.height = size + 'px';
            container.style.width = size + 'px';

            this.distributeFields(size);
            // smt
        },
        fetchFonts() {
            for (let style of this.$store.state.familyList['shop/manuka']) {
                var list = document.querySelectorAll(`.${style.slug} p`);
                for (let i = 0; i < list.length; i++) {
                    list[i].style.fontFamily = `${style.title}, sans-serif`;
                }
            }
        },
        fetchTrialBannerFont() {
            let familyName = this.$route.path.substring(1);

            fetch(`${process.env.VUE_APP_BASE_URL}cms/api/entries?id=trial-request-banners/${familyName}&token=522ed707c8c8490f117c3d037998f601`)
                .then((response) => {
                    if (!response.ok) {
                        throw new Error('Network response was not OK');
                    } else {
                        response.json()
                            .then((data) => {
                                let fontObject = new FontFace(`trial-${this.$route.name}`, `url(${this.$store.state.fileBaseURL}trial-request-banners/${familyName}/${data.data.font_file})`);
                                document.fonts.add(fontObject);
                                fontObject.load().then(function () {
                                    let trialEl = document.getElementById(`trial-${this.$route.path}`)
                                    trialEl.style.fontFamily = `trial-${this.$route.name}, sans-serif`;
                                }).catch(function (err) {
                                    throw new Error(err);
                                });
                            })
                    }
                })
        }
    }
}
</script>

<style>
#round-table-container {
    position: absolute;
    width: 100%;
    height: 100%;
    cursor: pointer;
}



.trial a:hover span {
    color: var(--contrast-color);
}

.trial a span {
    display: inline-block;
    font-family: trial-Manuka, sans-serif;
    font-size: 5rem;
    line-height: 1.2;
    transition: color 0s;
}

@screen md {
    .trial a span {
        font-size: min(16vw, 8rem);
    }
}

#mobile-nav {
    font-size: max(18px, 4.6vw);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    pointer-events: none;
    justify-content: space-between;
    align-items: center;
}

#mobile-nav img {
    flex-basis: 2.5rem;
    width: 2.5rem;
    padding: 10px;
    height: 2.5rem;
    pointer-events: auto;
}

#mobile-nav img svg {
    width: 100%;;
}

.center-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

#round-table {
    box-sizing: border-box;
    position: absolute;
    margin: auto;
    /* overflow: hidden !important;  */
    border-radius: 10000px;
    transform-origin: center center;
    transform: rotate(0deg);
    transition: transform 0.5s;
    pointer-events: none;
}


#round-table:hover {
    cursor: pointer;
}

.round-content {
    width: 20%;
    height: 20%;
    max-height: 80vh;
    position: absolute;
    color: #fff;
    pointer-events: none;
    white-space: pre-line;
}


.round-content img {
    position: relative;
    height: 100%;
    width: auto;
    pointer-events: none;
}

.text-area {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    font-size: 80px;
    color: var(--text-color);
    line-height: 1;
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    cursor: auto;
}

.fitty-text {
    pointer-events: auto;
}

p.fitty-text {
    position: relative;
    opacity: 0;
    transition: opacity 0.5s, color 1s 0s;
    max-width: 100vw;
    max-height: 100vh;
    padding: 40px;
    white-space: pre-wrap !important;
    font-size: 100px;
}

p.fitty-text.visible {
    opacity: 1;
}

#round-table.focus {
    padding-top: 0rem;
}

#round-table.plate img {
    opacity: 1;
}

#round-table img {
    opacity: 0;
    transition: opacity 1s;
}

#round-table a {
    pointer-events: auto;
}


@media only screen and (max-height: 400px) {
    #round-table .round-content {
        height: 15% !important;
        width: 15%;
    }
}

</style>