<template>
    <RoundTable v-if="manuka" :typeface="manuka"/>
</template>

<script>
import RoundTable from '../components/RoundTable.vue'

export default {
    components: {
        RoundTable
    },
    props: {
        manuka: Array
    }
}
</script>

<style>
</style>