import {createRouter, createWebHistory} from 'vue-router';
import ManukaTable from '../views/ManukaTable.vue';
import ManukaRoundTable from '../views/ManukaRoundTable.vue';
import DraftFont from '../views/DraftFont.vue';
import DraftFontRoundTable from "@/views/DraftFontRoundTable.vue";

const routes = [
    {
        path: '/',
        name: 'Home',
        component: ManukaTable
    },
    {
        path: '/manuka',
        name: 'Manuka',
        component: ManukaRoundTable
    },
    {
        path: "/draft",
        name: 'Draft',
        component: DraftFont
    },
    {
        path: '/draftroundtable',
        name: 'Draft Round Table',
        component: DraftFontRoundTable
    },
    {
        path: '/processed',
        name: 'Thank you!',
        component: () => import(/* webpackChunkName: "about" */ '../views/Processed.vue')
    },
    {
        path: '/cancelled',
        name: 'Cancelled',
        component: () => import(/* webpackChunkName: "about" */ '../views/Cancelled.vue')
    },
    {
        path: '/about',
        name: 'About',
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/cart',
        name: 'Cart',
        component: () => import(/* webpackChunkName: "shop" */ '../views/Cart.vue')
    },
    {
        path: '/terms-conditions',
        name: 'Terms and Conditions',
        component: () => import(/* webpackChunkName: "shop" */ '../views/Terms.vue')
    }
]
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === '/update') {
        window.location = to.fullPath
        return false
    } else {
        document.title = `Sunday Lunch | ${to.name}`;
        next();
    }
});

export default router
