<template>
    <div id="meta-container" class="" :class="{ visible : typeData[this.$store.state.focus] !== undefined }">

        <div class="meta-row px-2 pb-4 md:px-0 md:pb-0 border-b" :class="{ 'border' : unfold }">


            <div id="unfold" class="button px-5 pb-1 -mt-10 md:-mt-14" @click="unfold = !unfold">
                <span v-show="!unfold" class="flex" >
                    <p>read more</p>
                    <img src="@/assets/images/unfold-01.svg" class="w-6 h-auto pl-2 pt-1"/>
                </span>
                <span v-show="unfold" class="flex">
                    <p>read less</p>
                    <img src="@/assets/images/fold.svg" class="w-6 h-auto pl-2 pt-1"/>
                </span>
            </div>

            <div id="slider-container">
                <section>
                    <p>size</p>
                    <input
                        type="range"
                        step="1"
                        min="12"
                        max="250"
                        class="slider"
                        id="size-slider"
                        :value="metaSize"
                        @input="updateSize"
                    />
                    <p id="size-o" style="width: 35px">{{ Math.floor(metaSize / 10) }}</p>
                </section>

                <section class="md:pl-2">
                    <p>leading</p>
                    <input
                        type="range"
                        step="0.1"
                        min="0"
                        max="2"
                        class="slider"
                        id="leading-slider"
                        :value="leading"
                        @input="updateLeading"
                    />
                    <p id="leading-o" style="width: 35px">{{ leading }}</p>
                </section>

                <section>
                    <p>spacing</p>
                    <input
                        type="range"
                        step="1"
                        min="-20"
                        max="20"
                        class="slider"
                        id="size-slider"
                        :value="spacing"
                        @input="updateSpacing"
                    />
                    <p id="size-o" style="width: 35px">{{ spacing }}</p>
                </section>

                <div id="toggles">
                    <div class="button px-1 pb-0.5 lg:px-5 lg:pb-1" @click="togglePlate()"
                         :class="{'desktop' : !this.$store.state.$mobile}">
                        <p v-if="this.$store.state.plate == true">hide backdrop</p>
                        <p v-else>show backdrop</p>
                    </div>
                    <div class="button px-1 pb-0.5 lg:px-5 lg:pb-1" @click="toggleLightDark()">
                        <p v-if="this.$store.state.darkMode == false">dark theme</p>
                        <p v-else>light theme</p>
                    </div>
                </div>

            </div>


            <div id="nav-buttons">
                <div @click="backToTable()" class="button leading-tight center-content w-full">
                    <h3>back</h3>
                </div>
                <div @click="this.$store.commit('addToCart', typeData[this.$store.state.focus])" id="cart" class="button leading-tight center-content w-full">
                    <h3>add to cart</h3>
                </div>
            </div>
        </div>

        <div v-if="typeData">
        <div
            v-if="typeData[this.$store.state.focus] && typeData[this.$store.state.focus].description_text != undefined && unfold == true"
            class="meta-row description-container">
            <div class="description leading-snug" v-html="typeData[this.$store.state.focus].description_text"></div>
        </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            unfold: false
        }
    },
    props: {
        typeData: Array
    },
    computed: {
        metaSize() {
            return this.$store.state.metaSize;
        },
        spacing() {
            return this.$store.state.spacing;
        },
        leading() {
            return this.$store.state.leading;
        }
    },
    methods: {
        toggleLightDark() {
            if (document.body.classList.contains("darkmode")) {
                document.body.classList.remove("darkmode");
            } else {
                document.body.classList.add("darkmode");
            }
            this.$store.commit('toggleLightDark');
        },
        togglePlate() {
            this.$store.commit('togglePlate');
        },
        backToTable() {
            this.$router.push('/');
            this.$store.commit('setFocus', null);

        },
        updateSize(e) {
            this.$store.commit('setMetaSize', e.target.value)

            var textAreas = document.getElementById("round-table").getElementsByClassName("fitty-text");
            for (let el of textAreas) {
                el.style.fontSize = this.metaSize + "px";
            }
        },
        updateSpacing(e) {
            this.$store.commit('setSpacing', e.target.value)

            var textAreas = document.getElementById("round-table").getElementsByClassName("fitty-text");
            for (let el of textAreas) {
                el.style.letterSpacing = this.spacing + "px";
            }
        },
        updateLeading(e) {
            this.$store.commit('setLeading', e.target.value)

            var textAreas = document.getElementById("round-table").getElementsByClassName("fitty-text");
            for (let el of textAreas) {
                el.style.lineHeight = this.leading;
            }
        }
    }
};
</script>

<style>
#meta-container {
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 20px 0 0 0;
    transform: translateY(150%);
    transition: transform 0.5s;

    @screen md {
        padding: 20px 30px 20px 30px;
    }

    /* border-top: 5px dotted var(--slider-color); */
}

#meta-container.visible {
    transform: translateY(0%);
}


.meta-row {
    display: flex;
    flex-direction: row;
    border: none;
}

.meta-row.border {
    padding-bottom: 10px;
    border-width: 0 0 3px 0;
    border-style: dotted;
    border-color: var(--interface-slider);
}

.description {
    width: 100%;
    height: fit-content;
    text-align: left;
    @apply my-4 mx-4 md:mx-0;
}

#nav-buttons {
    flex-basis: 25%;
    display: flex;
    flex-direction: column;

    @screen md {
        margin-bottom: 10px;
    }
}

#nav-buttons .button {
    flex-basis: 50%;
    flex-grow: 1;
}

#nav-buttons .button:nth-child(1) {
    margin-bottom: 0.4rem;
    @screen md {
        margin-bottom: 10px;
    }
}

#unfold {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

#slider-container {
    width: 75%;
    padding: 0 1rem 0 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    @screen md {
        padding: 0 2rem 10px 0;
    }
}

#slider-container section {
    flex-basis: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @screen sm {
        flex-basis: 50%;
    }
}

#slider-container section p {
    padding: 0 0.5rem 0 0.5rem;
}

#meta-container input {
    @screen md {
        margin-top: 19px;
    }
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    max-width: 80%;
    height: 0px;
    margin-top: 15px;
    margin-bottom: 15px;
    background: transparent;
    border-width: 3px 0 0 0;
    border-style: dotted;
    border-color: var(--interface-slider);
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    /* z-index: -1; */
    /* pointer-events: none; */
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 18px;
    height: 18px;
    margin-top: -3px;
    border-radius: 30px;
    background-color: var(--slider-color);
    pointer-events: auto;
    cursor: pointer;
    z-index: 1;
}

.slider::-moz-range-thumb {
    width: 18px;
    height: 18px;
    border-radius: 35px 35px 30px 30px;
    background-color: var(--slider-color);
    pointer-events: auto;
    cursor: pointer;
    z-index: 1;
}

#round-table.darkmode >>> .slider::-webkit-slider-thumb,
#round-table.darkmode >>> .slider::-moz-range-thumb,
#round-table.darkmode >>> .slider {
    background-color: var(--darkmode-text-color) !important;
    background: var(--darkmode-text-color) !important;
}


#toggles {
    margin-top: 0.3rem;
    margin-left: 0.4rem;
    display: flex;
    flex-direction: row;

    @screen md {
        margin-top: 0;
    }
}

#toggles .button:nth-child(1) {
    margin-right: 5px;
}

.description-container {
    height: 40vh;
    overflow: auto;
}

@screen sm {
    .description-container {
        height: 50vh;
    }
}

@screen md {
    h3 {
        font-size: 20px;
    }
    .button p {
        margin: 0;
    }

    .description-container {
        height: 25vh;
        overflow: auto;
    }
}


</style>