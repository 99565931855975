require("dotenv").config();
import {createApp} from 'vue'
import {createStore} from 'vuex'
import App from './App.vue'
import './assets/style.css'
import './assets/fonts.css'
import router from './router'

const store = createStore({
    state() {
        return {
            darkMode: false,
            plate: true,
            clocks: true,
            focus: null,
            familyList: {},
            imageAPI: process.env.VUE_APP_BASE_URL + "cms/project/uploads/",
            fileBaseURL: process.env.VUE_APP_BASE_URL + "cms/project/media/entries/",
            cart: [],
            metaSize: 75,
            spacing: 0,
            leading: 1
        }
    },
    getters: {
        getFamilyList: state => {
            return state.familyList;
        }
    },
    mutations: {
        toggleLightDark(state) {
            state.darkMode = !state.darkMode;
        },
        togglePlate(state) {
            state.plate = !state.plate;
        },
        setFocus(state, value) {
            state.focus = value;
        },
        setFamilyList(state, [value, family]) {
            state.familyList[family] = value;
        },
        addToCart(state, value) {
            let cartbutton = document.getElementById('pulse');
            cartbutton.classList.add('pulse-animation');
            window.setTimeout(() => {
                cartbutton.classList.remove('pulse-animation');
            }, 2000)

            if (state.cart.filter(e => e.slug === value.slug).length == 0) {
                state.cart.push(value);

            }
        },
        removeFromCart(state, value) {
            if (state.cart.filter(e => e.slug === value.slug).length == 1) {
                state.cart = state.cart.filter(item => item !== value)
            }
        },
        setMetaSize(state, value) {
            state.metaSize = value;
        },
        setSpacing(state, value) {
            state.spacing = value;
        },
        setLeading(state, value) {
            state.leading = value;
        }
    }
})

createApp(App).use(router).use(store).mount('#app')
