<template>
    <Table :typeface="draft" routerlink="draftroundtable"/>
</template>

<script>
import Table from "@/components/Table.vue";

export default {
    components: {
        Table
    },
    props: {
        draft: Array
    }
}
</script>

<style>
</style>