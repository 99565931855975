<template>
    <div class="nav-top">
        <ul class="grow-0 basis-1/3 flex flex-col items-start gap-y-2">
            <router-link to="/about">
                <h3 class="button inline-flex">about</h3>
            </router-link>

            <router-link to="/manuka">
                <h3 id="family-drop-down" class="button inline-flex" :class="{ focus : this.$route.path === '/manuka' || $route.path === '/'}" v-if="this.$route.path !== '/cart'">
                    Manuka
                </h3>
            </router-link>

            <router-link to="/draft" v-if="draft">
                <h3 id="family-drop-down" class="button inline-flex" :class="{ focus : this.$route.path === '/draft'}" v-if="this.$route.path !== '/cart'">
                    Draft
                </h3>
            </router-link>
        </ul>

        <div class="basis-1/3">
            <router-link to="/">
                <h2 class="button inline-block">
                    <span class="StickyFat">Sunday</span>Lunch
                </h2>
            </router-link>
        </div>

        <div class="basis-1/3 flex justify-end">
            <router-link to="/cart">
                <h3 class="button inline-block" id="pulse">cart ({{ $store.state.cart.length }})</h3>
            </router-link>
        </div>

    </div>

    <router-view v-slot="{ Component }">
        <keep-alive include="Table">
            <component :is="Component" :manuka="manuka" :draft="draft" :key="'table'"/>
        </keep-alive>
    </router-view>
</template>

<script>

export default {
    name: 'App',
    created() {
        this.checkForMobile();
        window.addEventListener('resize', this.checkForMobile);
    },
    computed: {
        manuka() {
            return this.$store.getters.getFamilyList['shop/manuka'];
        },
        draft() {
            return this.$store.getters.getFamilyList['shop/font-family-2'];
        }
    },
    mounted() {
        fetch(`${process.env.VUE_APP_BASE_URL}cms/api/entries?id=shop&options[collection]=true&token=522ed707c8c8490f117c3d037998f601`)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not OK');
                } else {
                    response.json()
                        .then((data) => {
                            for (let family in data.data) {
                                if (data.data[family].visibility === 'visible') {
                                    fetch(`${process.env.VUE_APP_BASE_URL}cms/api/entries?id=${family}&options[collection]=true&token=522ed707c8c8490f117c3d037998f601`)
                                        .then((response) => response.json())
                                        .then((fontData) => {
                                            if (fontData.http_status_code == 404) {
                                                throw new Error(`Font Family ${family} doesn't have font styles!`);
                                            } else {
                                                // convert Object to Array !!!
                                                const entries = Object.values(fontData.data);
                                                const sortedEntries = entries.slice().sort((a, b) => a.sort_index - b.sort_index);

                                                if (family === "shop/manuka") {
                                                    sortedEntries.push({ type: "trial" });
                                                    console.log(family);
                                                }
                                                this.$store.commit('setFamilyList', [sortedEntries, family]);
                                                this.fetchFonts();
                                            }
                                        })
                                }
                            }
                        });
                }
            })
    },
    methods: {
        fetchFonts() {
            for (let style of this.$store.state.familyList['shop/manuka']) {
                let fontObject = new FontFace(`${style['title']}`, `url(${this.$store.state.fileBaseURL}${style['id']}/${style['font_file']})`);
                document.fonts.add(fontObject);

                fontObject.load().then(function () {
                    var list = document.querySelectorAll(`.${style['slug']} p`);
                    for (let i = 0; i < list.length; i++) {
                        list[i].style.fontFamily = `${style['title']}, sans-serif`;
                    }
                }).catch(function (err) {
                    throw new Error(err);
                });
            }
        },
        checkForMobile() {
            let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
            if (window.innerWidth <= 768 || isMobile) {
                this.$store.state.$mobile = true;
            } else {
                this.$store.state.$mobile = false;
            }
        }
    },
}
</script>

<style>
#family-drop-down.button.focus {
    background-color: var(--contrast-color);
}

.button.pulse-animation {
    animation: pulse 0.8s ease-in-out;
    animation-iteration-count: 2;
}

@keyframes pulse {
    0% {
        background-color: var(--interface)
    }
    50% {
        background-color: var(--contrast-color)
    }
    100% {
        background-color: var(--interface)
    }
}


.nav-bottom {
    transition: transform 1s;
}

.nav-bottom.hidden {
    transform: translateY(100%);
}

.button {
    z-index: 5;
}

.button.behind {
    z-index: 0 !important;
}


</style>
